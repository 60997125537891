/* You can add global styles to this file, and also import other style files */
@import 'primeicons/primeicons.css';
@import 'primeng/resources/primeng.css';
@import 'sass/theme/theme';
@import 'primeflex/primeflex.css';

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
  color: $textColor;
}

.p-treenode-label {
  width: 100%;
}

.p-tooltip {
  width: 400px;
  max-width: 70vw;
}

.p-organizationchart {
  .p-organizationchart-node-content {
    padding: 0;
  }
}

.p-input-icon-right > fa-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
  right: 10px;
}

.p-calendar.button-only {
  .p-inputtext {
    width: 0;
    padding: 0;
    margin: 0;
    border: 0;
    visibility: hidden;
  }

  .p-datepicker-trigger {
    border-top-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
    color: $secondaryButtonTextColor;
    background: $secondaryButtonBg;
    border: $secondaryButtonBorder;

    &:enabled:hover {
      background: $secondaryButtonHoverBg;
      color: $secondaryButtonTextHoverColor;
      border-color: $secondaryButtonHoverBorderColor;
    }

    &:enabled:focus {
      box-shadow: $secondaryButtonFocusShadow;
    }

    &:enabled:active {
      background: $secondaryButtonActiveBg;
      color: $secondaryButtonTextActiveColor;
      border-color: $secondaryButtonActiveBorderColor;
    }

    &.p-button-outlined {
      background-color: transparent;
      color: $secondaryButtonBg;
      border: $outlinedButtonBorder;

      &:enabled:hover {
        background: rgba($secondaryButtonBg, $textButtonHoverBgOpacity);
        color: $secondaryButtonBg;
        border: $outlinedButtonBorder;
      }

      &:enabled:active {
        background: rgba($secondaryButtonBg, $textButtonActiveBgOpacity);
        color: $secondaryButtonBg;
        border: $outlinedButtonBorder;
      }
    }
  }
}

.max-w-container {
  max-width: 1200px;
}

// fix scrolling fort the tree component
.p-scroller-viewport.p-element {
  display: flex;
  flex-direction: column;
}

.p-treetable .p-scroller-content {
  min-height: min-content;
}

.required {
  font-weight: bold !important;
  position: relative;
  padding-right: 6px;

  &::after {
    content: '*';
    position: absolute;
    right: 0;
    top: 0;
    font-size: 0.8rem;
  }
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0;
}

.min-h-screen {
  min-height: 100vh !important;
  min-height: -webkit-fill-available !important; /* stylelint-disable-line value-no-vendor-prefix */
}

.grid {
  margin-bottom: -1 * 0.5rem;
}

.input-border {
  border: 1px solid $shade400;
}

.border-input-color {
  border-color: $shade400;
}

app-percentage-input.ng-dirty.ng-invalid .p-inputnumber > .p-inputtext {
  @include invalid-input;
}

@media screen and (width <= 575px) {
  .p-button-icon-only-on-sm {
    width: $buttonIconOnlyWidth;
    padding: $buttonIconOnlyPadding;
    justify-content: center;

    & .p-button-label {
      display: none;
    }
  }
}

@media screen and (width <= 991px) {
  .p-button-icon-only-on-md {
    width: $buttonIconOnlyWidth;
    padding: $buttonIconOnlyPadding;
    justify-content: center;

    & .p-button-label {
      display: none;
    }
  }
}

.p-datepicker-monthpicker {
  min-width: 250px;
}

.light-tag {
  font-weight: $fontWeight;
  border: $inputBorder;
  background-color: transparent;
  color: $textColor;
}

.p-element.p-submenu-header span {
  white-space: nowrap;
}

.p-menubar .p-submenu-list {
  z-index: 3;
}

.tabular-nums {
  font-variant-numeric: tabular-nums;
}

.p-buttonset .p-button:first-of-type {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.p-buttonset .p-button:not(:first-of-type, :last-of-type) {
  border-radius: 0 !important;
}

.p-buttonset .p-button:last-of-type {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.p-dialog .p-dialog-content {
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.superset-container iframe {
  width: 100%;
  height: 100%;
  border: 0;
}
